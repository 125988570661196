import styled from 'styled-components';
import { BoxStyle, FontStyle } from 'styles/types';

const Span = styled.span<{ styles?: FontStyle & BoxStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
  ${props => props.styles?.padding && `padding: ${props.styles.padding};`}
`;

export default Span;
