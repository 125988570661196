import FlexBox from 'components/atoms/FlexBox';
import Img from 'components/atoms/Img';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ArrowLeftCircle from 'images/arrow-left-circle.svg';
import ArrowRightCircle from 'images/arrow-right-circle.svg';
import Ul from 'components/atoms/Ul';
import Li from 'components/atoms/Li';
import classnames from 'classnames';

const Icon = styled(Img)<{ styles: { left?: string; right?: string } }>`
  position: absolute;
  ${props => props.styles.left && `left: ${props.styles.left};`}
  ${props => props.styles.right && `right: ${props.styles.right};`}
  top: 50%;
  background-color: unset;
  cursor: pointer;
  width: 10%;
  height: 10%;
`;

const ImageSliderContainer = styled(FlexBox)`
  flex-direction: column;
  height: 100%;
  margin: 0 10px 0 0;
`;

const ImageSliderMainSection = styled(FlexBox)`
  position: relative;
  margin: 0 0 10px 0;
  width: 100%;
  height: 80%;

  ${Icon} {
    display: none;
  }

  :hover ${Icon} {
    display: initial;
  }
`;

const SubImage = styled(Img)`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;

  &.active,
  :hover {
    border: 2px solid #669cfe;
  }
`;

const List = styled(Ul)`
  overflow: scroll;
  margin: 10px 0;
`;

const ListItem = styled(Li)`
  width: 150px;
  height: 100px;
  margin: 0 5px 0 0;
  vertical-align: top;
  display: inline-block;
`;

type Props = {
  images: string[];
};

const ImageSlider: React.FC<Props> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextClick = useCallback(() => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  }, [images, currentImageIndex]);

  const handlePrevClick = useCallback(() => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }, [currentImageIndex]);

  const handleImageClick = useCallback((nextIndex: number) => {
    setCurrentImageIndex(nextIndex);
  }, []);

  return (
    <ImageSliderContainer>
      <ImageSliderMainSection>
        <Img src={images[currentImageIndex]} styles={{ width: '100%' }} />
        <Icon src={ArrowLeftCircle} onClick={handlePrevClick} styles={{ left: '5%' }} />
        <Icon src={ArrowRightCircle} onClick={handleNextClick} styles={{ right: '5%' }} />
      </ImageSliderMainSection>
      <List>
        {images.map((nextImage, nextImageIndex) => {
          return (
            <ListItem key={`list-item-${nextImageIndex}`}>
              <SubImage
                className={classnames({ active: currentImageIndex === nextImageIndex })}
                src={images[nextImageIndex]}
                onClick={() => handleImageClick(nextImageIndex)}
              />
            </ListItem>
          );
        })}
      </List>
    </ImageSliderContainer>
  );
};

export default ImageSlider;
