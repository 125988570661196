import { applyMiddleware, createStore, Middleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'modules/reducers';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from 'modules';
import { appActions } from 'modules/app';

const persistConfig = {
  key: 'root',
  storage,
};

const enhancedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const middlewares: Middleware<any, any, any>[] = [sagaMiddleware];
  const store = createStore(enhancedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
  const persistor = persistStore(store, null, () => {
    store.dispatch(appActions.init());
  });

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;
