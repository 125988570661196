import Img from 'components/atoms/Img';
import React from 'react';
import styled from 'styled-components';
import { CardImageCapProps } from './types';

const StyledCardImageCap = styled(Img)<{ styles?: CardImageCapProps['styles'] }>`
  height: ${props => props.styles?.height || '200px'};
`;

const CardImageCap: React.FC<CardImageCapProps> = ({ styles, src }) => {
  return <StyledCardImageCap styles={styles} src={src} />;
};

export default CardImageCap;
