import RadioButton from 'components/atoms/RadioButton';
import Span from 'components/atoms/Span';
import React from 'react';

type Props = {
  value: string | number;
  checked: boolean;
  onValueChange(value: string | number): void;
  name: string;
  count: number;
};

const RadioButtonController: React.FC<Props> = ({ value, checked, onValueChange, name, count }) => {
  return (
    <>
      <RadioButton value={value} checked={checked} onValueChange={() => onValueChange(value)} />
      <Span styles={{ fontSize: '14px', color: '#9299aa', margin: '0 5px' }}>{name}</Span>
      <Span styles={{ fontSize: '12px', color: '#bbd2ff' }}>{count}</Span>
    </>
  );
};

export default RadioButtonController;
