import React from 'react';
import styled from 'styled-components';

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  background-color: #fafafa;
`;

const Body: React.FC = ({ children }) => {
  return <StyledBody>{children}</StyledBody>;
};

export default Body;
