import React from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import SelectBoxButton from './SelectBoxButton';
import SelectBoxMenu from './SelectBoxMenu';
import SelectBoxOption from './SelectBoxOption';
import { SelectBoxType } from './types';
import SelectBoxContext from './SelectBoxContext';

const StyledSelectBox = styled.div`
  position: relative;
  display: block;
  width: 200px;
`;

const SelectBox = <T,>({ value, onValueChange, children }: SelectBoxType<T>) => {
  const [show, toggleShow] = useState(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const handleToggleShow = useCallback(() => {
    toggleShow(!show);
  }, [show, toggleShow]);

  const closeMenu = useCallback(() => {
    toggleShow(false);
  }, []);

  const handleValueChange = useCallback(
    (nextValue: T) => {
      onValueChange(nextValue);
      closeMenu();
    },
    [onValueChange, closeMenu]
  );

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const { target } = e;
      if (!(target instanceof Node && selectBoxRef.current?.contains(target))) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [closeMenu]);

  return (
    <SelectBoxContext.Provider
      value={{ value, show, closeMenu, onToggleShow: handleToggleShow, onValueChange: handleValueChange }}
    >
      <StyledSelectBox ref={selectBoxRef}>{children}</StyledSelectBox>
    </SelectBoxContext.Provider>
  );
};

SelectBox.Button = SelectBoxButton;
SelectBox.Menu = SelectBoxMenu;
SelectBox.Option = SelectBoxOption;

export default SelectBox;
