import { OrderType } from 'apis/vehiclesAPI/types';
import { vehicleActions, selectVehicle } from 'modules/vehicle';
import { FilterType } from 'modules/vehicle/type';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFilter = () => {
  const filter = useSelector(selectVehicle.filter);

  const dispatch = useDispatch();
  const selectBrand = useCallback(brandId => dispatch(vehicleActions.selectBrand({ brandId })), [dispatch]);
  const selectModelGroup = useCallback(
    modelGroupId => dispatch(vehicleActions.selectModelGroup({ modelGroupId })),
    [dispatch]
  );
  const selectModel = useCallback(modelId => dispatch(vehicleActions.selectModel({ modelId })), [dispatch]);
  const selectOrder = useCallback(
    (nextOrder: OrderType) => dispatch(vehicleActions.selectOrder(nextOrder)),
    [dispatch]
  );
  const selectNextPage = useCallback(() => dispatch(vehicleActions.selectNextPage()), [dispatch]);

  return {
    filter,
    selectOrder,
    selectNextPage,
    onValueChange: (id: number, type: FilterType) => {
      if (type === 'brand') {
        selectBrand(id);
      }
      if (type === 'modelGroup') {
        selectModelGroup(id);
      }
      if (type === 'model') {
        selectModel(id);
      }
    },
  };
};
