import 'moment/locale/ko';

import intl from 'react-intl-universal';
import moment from 'moment';

export const DEFAULT_LANGUAGE = 'ko';

export const configureLocale = async (locale: 'ko' | 'en' = DEFAULT_LANGUAGE): Promise<void> => {
  moment.locale(locale);
  return intl.init({
    currentLocale: locale,
    locales: {
      en: require('locale/en.json'),
      ko: require('locale/ko.json'),
    },
  });
};
