import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useVehicle } from 'components/pages/Vehicles/hooks/useVehicle';
import { useIntersection } from 'components/hooks/useIntersection';
import FlexBox from 'components/atoms/FlexBox';
import VehicleListItem from './VehicleListItem';
import { useFilter } from './hooks/useFilter';
import Ul from 'components/atoms/Ul';

const VehicleListContainer = styled(FlexBox)`
  padding: 0 20px;
  overflow: scroll;
  flex-direction: column;
`;

const FlexList = styled(Ul)``;

const VehicleList: React.FC = () => {
  const { list } = useVehicle();
  const { selectNextPage } = useFilter();
  const intersectionTarget = useRef<HTMLUListElement>(null);
  const { entry } = useIntersection(intersectionTarget);

  useEffect(() => {
    if (entry?.isIntersecting) {
      selectNextPage();
    }
  }, [entry, selectNextPage]);

  return (
    <VehicleListContainer>
      <FlexList>
        {list.data.map((vehicle, index) => {
          return <VehicleListItem key={`vehicle-list-item-${index}-${vehicle.id}`} vehicle={vehicle} />;
        })}
      </FlexList>
      <Ul ref={intersectionTarget}></Ul>
    </VehicleListContainer>
  );
};

export default VehicleList;
