import styled from 'styled-components';
import { BoxStyle } from 'styles/types';

const Img = styled.img<{ styles?: BoxStyle }>`
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: ${props => props.styles?.backgroundColor || '#343a40'};
  ${props => props.styles?.height && `height: ${props.styles.height};`}
  ${props => props.styles?.width && `width: ${props.styles.width};`}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
`;

export default Img;
