import { vehicleActions, selectVehicle } from 'modules/vehicle';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useVehicleDetail = () => {
  const { id } = useParams<{ id: string }>();
  const item = useSelector(selectVehicle.item);
  const images = item?.detail.images.map(image => {
    return image.url;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(vehicleActions.fetchCar(Number(id)));
  }, [dispatch, id]);

  return {
    item,
    images: images || [],
  };
};
