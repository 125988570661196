import React from 'react';
import styled from 'styled-components';

const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const CardBody: React.FC = ({ children }) => {
  return <StyledCardBody>{children}</StyledCardBody>;
};

export default CardBody;
