import React from 'react';
import styled from 'styled-components';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import CardImageCap from './CardImageCap';
import CardText from './CardText';
import CardTitle from './CardTitle';
import { CardComponent, CardProps } from './types';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
  height: 400px;
`;

const Card: React.FC<CardProps> & CardComponent = ({ className, onClick, children }) => {
  return (
    <StyledCard className={className} onClick={onClick}>
      {children}
    </StyledCard>
  );
};

Card.Header = CardHeader;
Card.ImageCap = CardImageCap;
Card.Body = CardBody;
Card.Title = CardTitle;
Card.Text = CardText;

export default styled(Card)``;
