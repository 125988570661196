import React from 'react';
import styled from 'styled-components';
import { CardHeaderProps } from './types';

const StyledCardHeader = styled.div<{ status: CardHeaderProps['status'] }>`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: ${props => props.theme.card.status[props.status || 'approved'].backgroundColor};
  border: ${props => props.theme.card.status[props.status || 'approved'].border};
`;

const CardHeader: React.FC<CardHeaderProps> = ({ status, children }) => {
  return <StyledCardHeader status={status}>{children}</StyledCardHeader>;
};

export default CardHeader;
