import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: #fafafa;
`;

const Content: React.FC = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

export default Content;
