import { selectVehicle } from 'modules/vehicle';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useVehicle = () => {
  const meta = useSelector(selectVehicle.meta);
  const list = useSelector(selectVehicle.list);
  const history = useHistory();
  const goToDetail = useCallback((id: number) => history.push(`/vehicles/${id}`), [history]);

  return {
    list,
    meta,
    goToDetail,
  };
};
