import Li from 'components/atoms/Li';
import Ul from 'components/atoms/Ul';
import styled from 'styled-components';
import React from 'react';
import RadioButtonController from '../RadioButtonController';
import { FilterType } from 'modules/vehicle/type';
import { BorderStyle, BoxStyle, ClassWithStyles } from 'styles/types';

const List = styled(Ul)`
  overflow: scroll;
`;

const ListItem = styled(Li)`
  margin: 0 0 10px 0;
  padding: 0 20px;
`;

type Props = {
  items: {
    id: number;
    name: string;
    auctions_count: number;
  }[];
  selectedId?: number;
  type: FilterType;
  onValueChange(id: number, type: FilterType): void;
} & ClassWithStyles<{ listStyles?: BoxStyle & BorderStyle }>;

const RadioButtonList: React.FC<Props> = ({ items, selectedId, type, onValueChange, styles, children }) => {
  return (
    <List styles={styles?.listStyles}>
      {items.map(item => {
        return (
          <div key={`${type}-${item.id}`}>
            <ListItem styles={{ margin: '0 0 10px 0', padding: '0 20px' }}>
              <RadioButtonController
                value={item.id}
                checked={selectedId === item.id}
                onValueChange={() => onValueChange(item.id, type)}
                name={item.name}
                count={item.auctions_count}
              />
            </ListItem>
            {selectedId === item.id && children}
          </div>
        );
      })}
    </List>
  );
};

export default RadioButtonList;
