import { RefObject, useCallback, useEffect, useState } from 'react';

export const useIntersection = <T extends RefObject<HTMLElement>>(target: T) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const handleObserver = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      setEntry(entry);
    },
    [setEntry]
  );

  useEffect(() => {
    const option = {
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (target.current) {
      observer.observe(target.current);
    }

    return () => observer.disconnect();
  }, [target, handleObserver]);

  return {
    entry,
  };
};
