import API from 'apis';
import { PostLoginParams, PostLoginResponse } from './types';

/**
 * POST /login/
 */
const postLogin = async (params: PostLoginParams) => {
  const res = await API.post<PostLoginResponse>('/login/', params);

  return res.data;
};

export { postLogin };
