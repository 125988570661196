import {
  GetBrandsResponse,
  GetCarResponse,
  GetModelGroupsResponse,
  GetModelsResponse,
  OrderType,
} from 'apis/vehiclesAPI/types';
import { AxiosError } from 'axios';
import { createAction } from 'typesafe-actions';
import { VehicleList } from './type';

/**
 * fetch brand actions
 */
export const FETCH_BRANDS = 'vehicle/FETCH_BRANDS';
export const FETCH_BRANDS_SUCCEDED = 'vehicle/FETCH_BRANDS_SUCCEDED';
export const FETCH_BRANDS_FAILED = 'vehicle/FETCH_BRANDS_FAILED';

/**
 * fetch model gorups actions
 */
export const FETCH_MODEL_GROUPS = 'vehicle/FETCH_MODEL_GROUPS';
export const FETCH_MODEL_GROUPS_SUCCEDED = 'vehicle/FETCH_MODEL_GROUPS_SUCCEDED';
export const FETCH_MODEL_GROUPS_FAILED = 'vehicle/FETCH_MODEL_GROUPS_FAILED';

/**
 * fetch models actions
 */
export const FETCH_MODELS = 'vehicle/FETCH_MODELS';
export const FETCH_MODELS_SUCCEDED = 'vehicle/FETCH_MODELS_SUCCEDED';
export const FETCH_MODELS_FAILED = 'vehicle/FETCH_MODELS_FAILED';

/**
 * fetch cars actions
 */
export const FETCH_CARS = 'vehicle/FETCH_CARS';
export const FETCH_CARS_SUCCEDED = 'vehicle/FETCH_CARS_SUCCEDED';
export const FETCH_CARS_FAILED = 'vehicle/FETCH_CARS_FAILED';

/**
 * fetch car actions
 */
export const FETCH_CAR = 'vehicle/FETCH_CAR';
export const FETCH_CAR_SUCCEDED = 'vehicle/FETCH_CAR_SUCCEDED';
export const FETCH_CAR_FAILED = 'vehicle/FETCH_CAR_FAILED';

/**
 * filter actions
 */
export const SELECT_BRAND = 'vehicle/SELECT_BRAND';
export const SELECT_MODEL_GROUP = 'vehicle/SELECT_MODEL_GROUP';
export const SELECT_MODEL = 'vehicle/SELECT_MODEL';
export const SELECT_ORDER = 'vehicle/SELECT_ORDER';
export const SELECT_NEXT_PAGE = 'vehicle/SELECT_NEXT_PAGE';

export const vehicleActions = {
  fetchBrands: createAction(FETCH_BRANDS)(),
  fetchBrandsSucceded: createAction(FETCH_BRANDS_SUCCEDED)<GetBrandsResponse>(),
  fetchBrandsFailed: createAction(FETCH_BRANDS_FAILED)<AxiosError>(),

  fetchModelGroups: createAction(FETCH_MODEL_GROUPS)(),
  fetchModelGroupsSucceded: createAction(FETCH_MODEL_GROUPS_SUCCEDED)<GetModelGroupsResponse>(),
  fetchModelGroupsFailed: createAction(FETCH_MODEL_GROUPS_FAILED)<AxiosError>(),

  fetchModels: createAction(FETCH_MODELS)(),
  fetchModelsSucceded: createAction(FETCH_MODELS_SUCCEDED)<GetModelsResponse>(),
  fetchModelsFailed: createAction(FETCH_MODELS_FAILED)<AxiosError>(),

  fetchCars: createAction(FETCH_CARS)(),
  fetchCarsSucceded: createAction(FETCH_CARS_SUCCEDED)<VehicleList>(),
  fetchCardsFailed: createAction(FETCH_CARS_FAILED)<AxiosError>(),

  fetchCar: createAction(FETCH_CAR)<number>(),
  fetchCarSucceded: createAction(FETCH_CAR_SUCCEDED)<GetCarResponse>(),
  fetchCarFailed: createAction(FETCH_CAR_FAILED)<AxiosError>(),

  selectBrand: createAction(SELECT_BRAND)<{ brandId: number }>(),
  selectModelGroup: createAction(SELECT_MODEL_GROUP)<{ modelGroupId: number }>(),
  selectModel: createAction(SELECT_MODEL)<{ modelId: number }>(),
  selectOrder: createAction(SELECT_ORDER)<OrderType>(),
  selectNextPage: createAction(SELECT_NEXT_PAGE)(),
};
