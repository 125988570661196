import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  display: flex;
  height: 60px;
  padding: 5px 20px;
  align-items: center;
  background-color: #414d6b;
  color: #fff;
`;

const Header: React.FC = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

export default Header;
