import FlexBox from 'components/atoms/FlexBox';
import { H2 } from 'components/atoms/Header';
import { useVehicle } from 'components/pages/Vehicles/hooks/useVehicle';
import React from 'react';
import { translate } from 'utils/locale';
import SelectBox from 'components/modules/SelectBox';
import { useRef } from 'react';
import { useIntersection } from 'components/hooks/useIntersection';
import { useEffect } from 'react';
import Layout from 'components/modules/Layout';
import Filter from './Filter';
import VehicleList from './VehicleList';
import { useFilter } from './hooks/useFilter';

const Vehicles: React.FC = () => {
  const { list, meta } = useVehicle();
  const { filter, selectOrder, selectNextPage } = useFilter();
  const intersectionTarget = useRef<HTMLUListElement>(null);
  const { entry } = useIntersection(intersectionTarget);

  useEffect(() => {
    if (entry?.isIntersecting) {
      selectNextPage();
    }
  }, [entry, selectNextPage]);

  return (
    <>
      <Layout.SideBar>
        <Filter title={translate('filter_title')} />
      </Layout.SideBar>
      <Layout.Content>
        <FlexBox styles={{ padding: '0 30px', alignItems: 'center', justifyContent: 'space-between' }}>
          <H2 styles={{ color: '#4d5f81' }}>
            {translate('vehicle_total_count', { totalCount: list.meta.totalCount })}
          </H2>
          <SelectBox value={filter.order} onValueChange={selectOrder}>
            <SelectBox.Button>{translate(`vehicle_order_${filter.order}`)}</SelectBox.Button>
            <SelectBox.Menu>
              {(meta.orders || []).map(nextValue => {
                return (
                  <SelectBox.Option key={`selectbox-option-${nextValue}`} value={nextValue}>
                    {translate(`vehicle_order_${nextValue}`)}
                  </SelectBox.Option>
                );
              })}
            </SelectBox.Menu>
          </SelectBox>
        </FlexBox>
        <VehicleList />
      </Layout.Content>
    </>
  );
};

export default Vehicles;
