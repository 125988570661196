/* eslint-disable no-param-reassign */
import { InitStatesType } from './type';
import produce from 'immer';
import {
  FETCH_BRANDS_SUCCEDED,
  FETCH_MODEL_GROUPS_SUCCEDED,
  SELECT_MODEL_GROUP,
  SELECT_BRAND,
  FETCH_MODELS_SUCCEDED,
  SELECT_MODEL,
  FETCH_CARS,
  FETCH_CARS_SUCCEDED,
  FETCH_CARS_FAILED,
  SELECT_ORDER,
  SELECT_NEXT_PAGE,
  FETCH_CAR_SUCCEDED,
  vehicleActions,
} from './actions';
import { ActionType, createReducer } from 'typesafe-actions';

const initStates: InitStatesType = {
  meta: {
    brands: [],
    modelGroups: [],
    models: [],
    orders: ['recent', 'bids_count'],
  },
  filter: {
    brandId: undefined,
    modelGroupId: undefined,
    modelId: undefined,
    order: 'recent',
    page: 1,
  },
  list: {
    meta: {
      totalCount: 0,
    },
    data: [],
    loading: false,
  },
};

export const vehicleReducer = createReducer<InitStatesType, ActionType<typeof vehicleActions>>(initStates, {
  [FETCH_BRANDS_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      draft.meta.brands = payload;
    }),
  [SELECT_BRAND]: (state, { payload }) =>
    produce(state, draft => {
      draft.filter = {
        ...draft.filter,
        brandId: payload.brandId,
        modelGroupId: undefined,
        modelId: undefined,
        page: 1,
      };
    }),
  [FETCH_MODEL_GROUPS_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      draft.meta.modelGroups = payload;
    }),
  [SELECT_MODEL_GROUP]: (state, { payload }) =>
    produce(state, draft => {
      draft.filter = { ...draft.filter, modelGroupId: payload.modelGroupId, modelId: undefined, page: 1 };
    }),
  [FETCH_MODELS_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      draft.meta.models = payload;
    }),
  [SELECT_MODEL]: (state, { payload }) =>
    produce(state, draft => {
      draft.filter = { ...draft.filter, modelId: payload.modelId, page: 1 };
    }),
  [FETCH_CARS]: state =>
    produce(state, draft => {
      draft.list.loading = true;
    }),
  [FETCH_CARS_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      const { data, meta } = payload;
      draft.list.meta = meta;
      draft.list.loading = false;
      if (draft.filter.page > 1) {
        draft.list.data = [...draft.list.data, ...data];
      } else {
        draft.list.data = data;
      }
    }),
  [FETCH_CARS_FAILED]: state =>
    produce(state, draft => {
      draft.list.loading = true;
    }),
  [SELECT_ORDER]: (state, { payload }) =>
    produce(state, draft => {
      draft.filter = { ...draft.filter, order: payload, page: 1 };
    }),
  [SELECT_NEXT_PAGE]: state =>
    produce(state, draft => {
      draft.filter.page += 1;
    }),
  [FETCH_CAR_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      draft.item = payload;
    }),
});
