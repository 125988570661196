import React from 'react';
import { useCallback } from 'react';

type Props<T> = {
  value: T;
  checked: boolean;
  onValueChange(value: T): void;
};

const RadioButton = <T extends string | number>({ value, checked, onValueChange }: Props<T>) => {
  const handleChange = useCallback(() => {
    onValueChange(value);
  }, [onValueChange, value]);

  return <input type="radio" style={{ margin: 0 }} value={value} checked={checked} onChange={handleChange} />;
};

export default RadioButton;
