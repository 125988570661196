import { useContext } from 'react';
import SelectBoxContext from './SelectBoxContext';
import { SelectBoxContextType } from './types';

const useSelectBoxContext = <T>() => {
  const { value, show, closeMenu, onValueChange, onToggleShow } = useContext<SelectBoxContextType<T>>(SelectBoxContext);
  return {
    value,
    show,
    closeMenu,
    onValueChange,
    onToggleShow,
  };
};

export default useSelectBoxContext;
