/* eslint-disable no-param-reassign */
import { put, take, fork, call } from 'redux-saga/effects';
import { LOGIN_FAILED, LOGIN_SUCCEDED, sessionActions } from 'modules/session';
import { createReducer, createAction, ActionType } from 'typesafe-actions';
import produce from 'immer';
import { setAuthenticationHeader } from 'apis';
import { FETCH_BRANDS_FAILED, FETCH_CARS_FAILED, FETCH_CARS_SUCCEDED, vehicleActions } from 'modules/vehicle';

export const INIT = 'app/INIT';
export const INIT_SUCCEDED = 'app/INIT_SUCCEDED';
export const INIT_FAILED = 'app/INIT_FAILED';

export const appActions = {
  init: createAction(INIT)(),
  initSucceded: createAction(INIT_SUCCEDED)(),
  initFailed: createAction(INIT_FAILED)(),
};

type InitStatesType = {
  isInit: boolean;
};

const initStates: InitStatesType = {
  isInit: false,
};

export const appReducer = createReducer<InitStatesType, ActionType<typeof appActions>>(initStates, {
  [INIT_SUCCEDED]: state =>
    produce(state, draft => {
      draft.isInit = true;
    }),
});

function* watchInit() {
  while (true) {
    const actions: ActionType<typeof sessionActions & typeof vehicleActions> = yield take([
      FETCH_CARS_SUCCEDED,
      LOGIN_FAILED,
      FETCH_BRANDS_FAILED,
      FETCH_CARS_FAILED,
    ]);

    if (actions.type === LOGIN_FAILED || actions.type === FETCH_BRANDS_FAILED || actions.type === FETCH_CARS_FAILED) {
      yield put(appActions.initFailed());
    } else {
      yield put(appActions.initSucceded());
    }
  }
}

function* initFlow() {
  while (true) {
    yield take(INIT);
    yield put(sessionActions.login());

    const action: ActionType<typeof sessionActions.loginSucceded> = yield take(LOGIN_SUCCEDED);
    const { token } = action.payload;
    yield call(setAuthenticationHeader, token);

    yield put(vehicleActions.fetchBrands());
    yield put(vehicleActions.fetchCars());
  }
}

export function* appSagas() {
  yield fork(initFlow);
  yield fork(watchInit);
}
