import { combineReducers } from 'redux';
import { vehicleReducer } from './vehicle';
import { sessionReducer } from './session';
import { appReducer } from './app';

const rootReducer = combineReducers({
  app: appReducer,
  session: sessionReducer,
  vehicle: vehicleReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
