import styled from 'styled-components';
import { FontStyle } from 'styles/types';

const H1 = styled.h1<{ styles?: FontStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
`;

const H2 = styled.h2<{ styles?: FontStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
`;

const H3 = styled.h3<{ styles?: FontStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
`;

const H4 = styled.h4<{ styles?: FontStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
`;

const H5 = styled.h5<{ styles?: FontStyle }>`
  ${props => props.styles?.color && `color: ${props.styles.color};`}
  ${props => props.styles?.fontSize && `font-size: ${props.styles.fontSize};`}
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
`;

export { H1, H2, H3, H4, H5 };
