import React from 'react';
import styled from 'styled-components';
import { SideBarProps } from './types';

const StyledSideBar = styled.div<{ styles?: SideBarProps['styles'] }>`
  display: flex;
  width: ${props => props.styles?.width || '20%'};
  flex-direction: column;
  background-color: #fafafa;
`;

const SideBar: React.FC<SideBarProps> = ({ styles, children }) => {
  return <StyledSideBar styles={styles}>{children}</StyledSideBar>;
};

export default SideBar;
