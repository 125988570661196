import Img from 'components/atoms/Img';
import Layout from 'components/modules/Layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import VehicleDetail from './VehicleDetail';
import Vehicles from './Vehicles';
import IconLogo from 'images/icon-logo.png';

const Main = () => {
  return (
    <Layout>
      <Layout.Header>
        <Img src={IconLogo} width={100} styles={{ backgroundColor: 'transparent' }} />
      </Layout.Header>
      <Layout.Body>
        <Switch>
          <Route exact path={['/', '/vehicles']}>
            <Vehicles />
          </Route>
          <Route path="/vehicles/:id">
            <VehicleDetail />
          </Route>
        </Switch>
      </Layout.Body>
    </Layout>
  );
};

export default Main;
