import React from 'react';
import styled from 'styled-components';
import { SelectBoxOptionType } from './types';
import useSelectBoxContext from './useSelectBoxContext';

const StyledSelectBoxOption = styled.div`
  display: block;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  color: #4e638e;
  text-align: left;

  &:hover {
    background-color: #e9ecef;
  }
`;

const SelectBoxOption = <T,>({ value, children }: SelectBoxOptionType<T>) => {
  const { onValueChange } = useSelectBoxContext<T>();

  return <StyledSelectBoxOption onClick={() => onValueChange(value)}>{children}</StyledSelectBoxOption>;
};

export default SelectBoxOption;
