import { createGlobalStyle } from 'styled-components';
import { Theme } from './types';

const theme: Theme = {
  card: {
    status: {
      ended: {
        border: '1px solid #51be92',
        backgroundColor: '#51be92',
      },
      approved: {
        border: '1px solid #669cfe',
        backgroundColor: '#669cfe',
      },
      expired: {
        border: '1px solid #dbe1ea',
        backgroundColor: '#dbe1ea',
      },
    },
    title: '#627280',
    text: '#9fa5b4',
  },
};

const GlobalStyle = createGlobalStyle`
  html, body {
    position: relative;
    height: 100%;
    font-size: 15px;
    font-style: normal;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  #root {
    height: 100%;
  }

  b, strong {
    font-weight: bold;
  }
`;

export { theme, GlobalStyle };
