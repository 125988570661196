import React from 'react';
import styled from 'styled-components';
import useSelectBoxContext from './useSelectBoxContext';

const StyledSelectBoxButton = styled.div`
  position: relative;
  width: calc(100% - 20px);
  align-items: center;
  cursor: pointer;
  border: 1px solid #aeb9d0;
  border-radius: 2px;
  padding: 10px;
  color: #4e638e;
  font-size: 15px;
`;

const SelectBoxButton: React.FC = ({ children }) => {
  const { onToggleShow } = useSelectBoxContext();

  return <StyledSelectBoxButton onClick={onToggleShow}>{children}</StyledSelectBoxButton>;
};

export default SelectBoxButton;
