import ImageSlider from 'components/modules/ImageSlider';
import Layout from 'components/modules/Layout';
import React from 'react';
import VehicleInfo from './VehicleInfo';
import { useVehicleDetail } from 'components/pages/VehicleDetail/hooks/useVehicleDetail';

const VehicleDetail: React.FC = () => {
  const { images } = useVehicleDetail();
  return (
    <>
      <Layout.Content>
        <ImageSlider images={images} />
      </Layout.Content>
      <Layout.SideBar>
        <VehicleInfo />
      </Layout.SideBar>
    </>
  );
};

export default VehicleDetail;
