import FlexBox from 'components/atoms/FlexBox';
import { H4 } from 'components/atoms/Header';
import { useFilter } from 'components/pages/Vehicles/hooks/useFilter';
import RadioButtonList from 'components/modules/RadioButtonList';
import React from 'react';
import { useVehicle } from './hooks/useVehicle';
import styled from 'styled-components';

const FilterContainer = styled(FlexBox)`
  flex-direction: column;
  border: 1px solid #a4b3c8;
  overflow: scroll;
  background-color: #fff;
`;

type Props = {
  title: string;
};

const Filter: React.FC<Props> = ({ title }) => {
  const { onValueChange, filter } = useFilter();
  const { meta } = useVehicle();
  const { brands, modelGroups, models } = meta;

  return (
    <FilterContainer>
      <FlexBox styles={{ padding: '0 20px' }}>
        <H4 styles={{ color: '#a4b3c8' }}>{title}</H4>
      </FlexBox>
      <RadioButtonList items={brands} selectedId={filter?.brandId} type="brand" onValueChange={onValueChange}>
        {modelGroups && modelGroups.length > 0 && (
          <RadioButtonList
            items={modelGroups}
            selectedId={filter?.modelGroupId}
            type="modelGroup"
            onValueChange={onValueChange}
            styles={{
              listStyles: {
                maxHeight: '200px',
                margin: '0 0 10px 0',
                padding: '10px 15px',
                border: '1px solid #eceef4',
                backgroundColor: '#f9fbfd',
              },
            }}
          >
            {models && models.length > 0 && (
              <RadioButtonList
                items={models}
                selectedId={filter?.modelId}
                type="model"
                onValueChange={onValueChange}
                styles={{
                  listStyles: {
                    maxHeight: '200px',
                    margin: '0',
                    padding: '0 15px',
                  },
                }}
              />
            )}
          </RadioButtonList>
        )}
      </RadioButtonList>
    </FilterContainer>
  );
};

export default Filter;
