import { createContext } from 'react';
import { SelectBoxContextType } from './types';

const SelectBoxContext = createContext<SelectBoxContextType<any>>({
  show: false,
  closeMenu: () => {},
  onToggleShow: () => {},
  onValueChange: () => {},
});

export default SelectBoxContext;
