import { H4 } from 'components/atoms/Header';
import React from 'react';
import styled from 'styled-components';
import { CardTitleProps } from './types';

const StyledCardTitle = styled(H4)`
  height: 50px;
  margin: 10px 0;
  color: ${props => props.theme.card.title};
`;

const CardTitle: React.FC<CardTitleProps> = ({ className, styles, children }) => {
  return (
    <StyledCardTitle className={className} styles={styles}>
      {children}
    </StyledCardTitle>
  );
};

export default CardTitle;
