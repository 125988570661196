import configureStore from 'config/configureStore';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'styles';
import Main from 'components/pages/Main';
import { BrowserRouter } from 'react-router-dom';
import { configureAxios } from 'apis';
import { configureLocale } from 'config/configureLocale';

configureLocale();
configureAxios();
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Main />
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
