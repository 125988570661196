import React from 'react';
import styled from 'styled-components';
import useSelectBoxContext from './useSelectBoxContext';

const StyledSelectBoxMenu = styled.ul`
  display: block;
  position: absolute;
  width: 100%;
  z-index: 1000;
  padding: 2px 0;
  margin: 2px 0;

  background-color: #fff;
  border: 1px solid #aeb9d0;
  border-radius: 2px;
`;

const SelectBoxMenu: React.FC = ({ children }) => {
  const { show } = useSelectBoxContext();
  if (show) {
    return <StyledSelectBoxMenu>{children}</StyledSelectBoxMenu>;
  }

  return <></>;
};

export default SelectBoxMenu;
