import React from 'react';
import styled from 'styled-components';
import Body from './Body';
import Content from './Content';
import Header from './Header';
import SideBar from './SideBar';
import { LayoutComponent } from './types';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Layout: React.FC & LayoutComponent = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

Layout.Header = Header;
Layout.Body = Body;
Layout.Content = Content;
Layout.SideBar = SideBar;

export default Layout;
