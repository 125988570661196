import { getBrands, getCar, getCars, getModelGroups, getModels } from 'apis/vehiclesAPI';
import {
  GetBrandsResponse,
  GetCardsResponse,
  GetCarResponse,
  GetModelGroupsResponse,
  GetModelsResponse,
} from 'apis/vehiclesAPI/types';
import { AxiosError } from 'axios';
import { call, fork, put, select, take, takeLeading } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { selectVehicle } from '.';
import {
  FETCH_BRANDS,
  SELECT_MODEL_GROUP,
  SELECT_BRAND,
  SELECT_MODEL,
  FETCH_CARS,
  SELECT_ORDER,
  SELECT_NEXT_PAGE,
  FETCH_MODELS,
  FETCH_MODEL_GROUPS,
  FETCH_CAR,
  vehicleActions,
} from './actions';

function* handleFetchBrands() {
  try {
    const result: GetBrandsResponse = yield call(getBrands);
    yield put(vehicleActions.fetchBrandsSucceded(result));
  } catch (e) {
    yield put(vehicleActions.fetchBrandsFailed(e as AxiosError));
  }
}

function* handleFetchModelGroups() {
  const { brandId }: ReturnType<typeof selectVehicle.filter> = yield select(selectVehicle.filter);
  if (brandId) {
    try {
      const result: GetModelGroupsResponse = yield call(getModelGroups, brandId);
      yield put(vehicleActions.fetchModelGroupsSucceded(result));
    } catch (e) {
      yield put(vehicleActions.fetchModelGroupsFailed(e as AxiosError));
    }
  }
}

function* handleFetchModels() {
  const { modelGroupId }: ReturnType<typeof selectVehicle.filter> = yield select(selectVehicle.filter);
  if (modelGroupId) {
    try {
      const result: GetModelsResponse = yield call(getModels, modelGroupId);
      yield put(vehicleActions.fetchModelsSucceded(result));
    } catch (e) {
      yield put(vehicleActions.fetchModelsFailed(e as AxiosError));
    }
  }
}

function* handleFetchCards() {
  const { page, brandId, modelGroupId, modelId, order }: ReturnType<typeof selectVehicle.filter> = yield select(
    selectVehicle.filter
  );
  try {
    const result: GetCardsResponse = yield call(getCars, {
      order,
      brand: brandId,
      model_group: modelGroupId,
      model: modelId,
      page,
    });
    yield put(
      vehicleActions.fetchCarsSucceded({
        data: result.results,
        meta: {
          totalCount: result.count,
        },
      })
    );
  } catch (e) {
    yield put(vehicleActions.fetchCardsFailed(e as AxiosError));
  }
}

function* handleFetchCar(action: ActionType<typeof vehicleActions.fetchCar>) {
  try {
    const result: GetCarResponse = yield call(getCar, action.payload);
    yield put(vehicleActions.fetchCarSucceded(result));
  } catch (e) {
    yield put(vehicleActions.fetchCarFailed(e as AxiosError));
  }
}

function* selectFilterFlow() {
  while (true) {
    const action: ActionType<typeof vehicleActions> = yield take([
      SELECT_BRAND,
      SELECT_MODEL_GROUP,
      SELECT_MODEL,
      SELECT_ORDER,
      SELECT_NEXT_PAGE,
    ]);
    if (action.type === SELECT_BRAND) {
      yield put(vehicleActions.fetchModelGroups());
    }
    if (action.type === SELECT_MODEL_GROUP) {
      yield put(vehicleActions.fetchModels());
    }

    yield put(vehicleActions.fetchCars());
  }
}

export function* vehicleSagas() {
  yield fork(selectFilterFlow);
  yield takeLeading(FETCH_CARS, handleFetchCards);
  yield takeLeading(FETCH_MODELS, handleFetchModels);
  yield takeLeading(FETCH_MODEL_GROUPS, handleFetchModelGroups);
  yield takeLeading(FETCH_BRANDS, handleFetchBrands);
  yield takeLeading(FETCH_CAR, handleFetchCar);
}
