import React from 'react';
import styled from 'styled-components';
import { BorderStyle, BoxStyle, ClassWithStyles, FlexStyle } from 'styles/types';

type Props = ClassWithStyles<BoxStyle & FlexStyle & BorderStyle>;

const StyledFlexBox = styled.div<{ styles: Props['styles'] }>`
  display: flex;
  ${props => props.styles?.position && `position: ${props.styles.position};`}
  ${props => props.styles?.flexDirection && `flex-direction: ${props.styles.flexDirection};`}
  ${props => props.styles?.justifyContent && `justify-content: ${props.styles.justifyContent};`}
  ${props => props.styles?.alignItems && `align-items: ${props.styles.alignItems};`}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
  ${props => props.styles?.flex && `flex: ${props.styles.flex};`}
  ${props => props.styles?.height && `height: ${props.styles.height};`}
  ${props => props.styles?.width && `width: ${props.styles.width};`}
  ${props => props.styles?.padding && `padding: ${props.styles.padding};`}
  ${props => props.styles?.border && `border: ${props.styles.border};`}
  ${props => props.styles?.borderRadius && `border-radius: ${props.styles.borderRadius};`}
  ${props => props.styles?.boxShadow && `box-shadow: ${props.styles.boxShadow};`}
  ${props => props.styles?.overflow && `overflow: ${props.styles.overflow};`}
  ${props => props.styles?.minHeight && `min-height: ${props.styles.minHeight};`}
  ${props => props.styles?.backgroundColor && `background-color: ${props.styles.backgroundColor};`}
  ${props => props.styles?.flexWrap && `flex-wrap: ${props.styles.flexWrap};`}
`;

const FlexBox: React.FC<Props> = ({ className, styles, children }) => {
  return (
    <StyledFlexBox className={className} styles={styles}>
      {children}
    </StyledFlexBox>
  );
};

export default styled(FlexBox)``;
