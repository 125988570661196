/* eslint-disable no-param-reassign */
import { postLogin } from 'apis/sessionAPI';
import { AxiosError } from 'axios';
import { ActionType, createReducer, createAction } from 'typesafe-actions';
import { produce } from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PostLoginResponse } from 'apis/sessionAPI/types';

export const LOGIN = 'session/LOGIN';
export const LOGIN_SUCCEDED = 'session/LOGIN_SUCCEDED';
export const LOGIN_FAILED = 'session/LOGIN_FAILED';

export const sessionActions = {
  login: createAction(LOGIN)(),
  loginSucceded: createAction(LOGIN_SUCCEDED)<PostLoginResponse>(),
  loginFailed: createAction(LOGIN_FAILED)<AxiosError>(),
};

type InitStatesType = {
  token: string;
};
const initStates = {
  token: '',
};

export const sessionReducer = createReducer<InitStatesType, ActionType<typeof sessionActions>>(initStates, {
  [LOGIN_SUCCEDED]: (state, { payload }) =>
    produce(state, draft => {
      draft.token = payload.token;
    }),
});

function* handleLogin() {
  try {
    const result: PostLoginResponse = yield call(postLogin, {
      username: 'test',
      password: '0000',
    });
    yield put(sessionActions.loginSucceded(result));
  } catch (e) {
    yield put(sessionActions.loginFailed(e as AxiosError));
  }
}

export function* sessionSagas() {
  yield takeLatest(LOGIN, handleLogin);
}
