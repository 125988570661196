import styled from 'styled-components';
import { BorderStyle, BoxStyle } from 'styles/types';

const Ul = styled.ul<{ styles?: BoxStyle & BorderStyle }>`
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
  ${props => props.styles?.height && `height: ${props.styles.height};`}
  ${props => props.styles?.width && `width: ${props.styles.width};`}
  padding: ${props => props.styles?.padding || '0'};
  ${props => props.styles?.border && `border: ${props.styles.border};`}
  ${props => props.styles?.borderRadius && `border-radius: ${props.styles.borderRadius};`}
  ${props => props.styles?.boxShadow && `box-shadow: ${props.styles.boxShadow};`}
  ${props => props.styles?.overflow && `overflow: ${props.styles.overflow};`}
  ${props => props.styles?.minHeight && `min-height: ${props.styles.minHeight};`}
   ${props => props.styles?.maxHeight && `max-height: ${props.styles.maxHeight};`}
  ${props => props.styles?.backgroundColor && `background-color: ${props.styles.backgroundColor};`}
`;

export default Ul;
