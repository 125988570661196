import Span from 'components/atoms/Span';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { translate } from 'utils/locale';

const MILLISECONDS = 1000;

type Props = {
  dateTime: string;
};

const Timer: React.FC<Props> = ({ dateTime }) => {
  const [timeDiff, setTimeDiff] = useState<moment.Duration>();

  useEffect(() => {
    const tick = () => {
      setTimeDiff(timeDiff?.clone().subtract(1, 'seconds'));
    };

    const timeTick = setInterval(() => {
      tick();
    }, MILLISECONDS);

    return () => clearInterval(timeTick);
  }, [timeDiff]);

  useEffect(() => {
    const currentTime = moment();
    const formattedTime = moment(dateTime, translate('date_time_format'));
    const nextTimeDiff = moment.duration(formattedTime.diff(currentTime));

    setTimeDiff(nextTimeDiff);
  }, [dateTime]);

  return <Span>{moment(timeDiff?.asMilliseconds()).format(translate('time_format'))}</Span>;
};

export default Timer;
