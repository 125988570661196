import FlexBox from 'components/atoms/FlexBox';
import Card from 'components/modules/Card';
import CardHeaderWithTimer from 'components/modules/Card/CardHeaderWithTimer';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { translate } from 'utils/locale';
import { useVehicleDetail } from 'components/pages/VehicleDetail/hooks/useVehicleDetail';

const VehicleInfoContainer = styled(FlexBox)`
  flex-direction: column;

  ${Card} {
    width: 100%;
    height: 100%;
    margin: 0 0 5px 0;
  }
`;

const VehicleInfo: React.FC = () => {
  const { item } = useVehicleDetail();
  return (
    <VehicleInfoContainer>
      {item && (
        <>
          <Card>
            <CardHeaderWithTimer data={item.auction}>
              {translate(`vehicle_status_${item.auction.status}`)}
            </CardHeaderWithTimer>
            <Card.Body>
              <Card.Title>{item.detail.name}</Card.Title>
              <Card.Text>
                {moment(item.detail.initial_registration_date).format(translate('date_month_format'))}
                {translate('vehicle_year', { year: item.detail.year })}
              </Card.Text>
              <Card.Text>{translate('km', { distance: item.detail.mileage.toLocaleString() })}</Card.Text>
              <Card.Text>{translate(`vehicle_fuel_${item.detail.fuel}`)}</Card.Text>
              <Card.Text>{item.detail.location}</Card.Text>
            </Card.Body>
          </Card>
          {item.auction.status !== 'approved' && (
            <Card>
              <Card.Body>
                <Card.Title>{translate('auction_result')}</Card.Title>
                <FlexBox>
                  <FlexBox styles={{ flexDirection: 'column', margin: '0 10px 0 0' }}>
                    <Card.Text>{translate('bids_count')}</Card.Text>
                    <Card.Text>{item.auction.bids_count}</Card.Text>
                  </FlexBox>
                  <FlexBox styles={{ flexDirection: 'column' }}>
                    <Card.Text>{translate('highest_bid_price')}</Card.Text>
                    <Card.Text>
                      {translate('price', { price: item.auction.highest_bid?.price.toLocaleString() || 0 })}
                    </Card.Text>
                  </FlexBox>
                </FlexBox>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </VehicleInfoContainer>
  );
};

export default VehicleInfo;
