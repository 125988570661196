import API from 'apis';
import {
  GetBrandsResponse,
  GetModelGroupsResponse,
  GetModelsResponse,
  GetCardsParams,
  GetCardsResponse,
  GetCarResponse,
} from './types';

/**
 * GET /brands/
 */
export const getBrands = async () => {
  const res = await API.get<GetBrandsResponse>('/brands/');

  return res.data;
};

/**
 * GET /brands/:id/model_groups/
 */
export const getModelGroups = async (id: number) => {
  const res = await API.get<GetModelGroupsResponse>(`/brands/${id}/model_groups/`);

  return res.data;
};

/**
 * GET /model_groups/{model_group_id}/models/
 */
export const getModels = async (id: number) => {
  const res = await API.get<GetModelsResponse>(`/model_groups/${id}/models/`);

  return res.data;
};

/**
 * GET /cars/
 */
export const getCars = async (params: GetCardsParams) => {
  const res = await API.get<GetCardsResponse>('/cars/', { params });

  return res.data;
};

/**
 * GET cars/:id/
 */
export const getCar = async (id: number) => {
  const res = await API.get<GetCarResponse>(`/cars/${id}/`);

  return res.data;
};
