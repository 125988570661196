import { CarAuction } from 'apis/vehiclesAPI/types';
import Timer from 'components/modules/Timer';
import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import Card from '..';

export type Props = {
  data: Pick<CarAuction, 'status' | 'expire_at' | 'end_at'>;
};

const CardHeaderWithTimer: React.FC<Props> = ({ data, children }) => {
  const isExpired = useMemo(() => data.status === 'expired', [data.status]);

  const dataTime = useMemo(() => {
    if (data.status === 'approved') {
      return data.end_at;
    }
    return data.expire_at;
  }, [data]);

  return (
    <Card.Header status={data.status}>
      {children}
      {!isExpired && <Timer dateTime={dataTime} />}
    </Card.Header>
  );
};

export default styled(CardHeaderWithTimer)``;
