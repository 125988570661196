import React from 'react';
import styled from 'styled-components';
import Card from 'components/modules/Card';
import CardHeaderWithTimer from 'components/modules/Card/CardHeaderWithTimer';
import { useVehicle } from 'components/pages/Vehicles/hooks/useVehicle';
import { VehicleListItemProps } from './types';
import moment from 'moment';
import { translate } from 'utils/locale';
import Li from 'components/atoms/Li';

const DIVISION_VALUE = 10000;

const DECIMAL_VALUE = 10;

const ListItem = styled(Li)`
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  margin: 0 10px 10px 10px;
  cursor: pointer;
`;

const VehicleListItem: React.FC<VehicleListItemProps> = ({ vehicle }) => {
  const { goToDetail } = useVehicle();
  return (
    <ListItem>
      <Card onClick={() => goToDetail(vehicle.id)}>
        <CardHeaderWithTimer data={vehicle.auction}>
          {translate(`vehicle_status_${vehicle.auction.status}`)}
        </CardHeaderWithTimer>
        <Card.ImageCap src={vehicle.detail.main_image?.url} />
        <Card.Body>
          <Card.Title>{vehicle.detail.name}</Card.Title>
          <Card.Text>
            {moment(vehicle.detail.initial_registration_date).format(translate('date_month_format'))}
            {translate('vehicle_year', { year: vehicle.detail.year })}
          </Card.Text>
          <Card.Text>
            {translate('vehicle_mileage', {
              mileage: Math.round(vehicle.detail.mileage / DIVISION_VALUE) / DECIMAL_VALUE,
            })}
          </Card.Text>
          <Card.Text>{vehicle.detail.location}</Card.Text>
        </Card.Body>
      </Card>
    </ListItem>
  );
};

export default VehicleListItem;
